'use client';

import React, { useEffect } from 'react';
import './popup.css';  

export default function Popup({ onClose }) {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('popup-overlay')) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOverlayClick);

    return () => {
      document.removeEventListener('click', handleOverlayClick);
    };
  }, []);

  return (
    <div className="popup-overlay">
      <div className="popup-box">
        
        <video 
          className="popup-video" 
          src="./video/teasertrailer_wwwords.io.mp4" 
          autoPlay 
          controls 
          loop 
          muted 
        >
          Your browser does not support our video - everything else will still work for you.
        </video>

        <ul>
          <li>
            <strong>Find a place with ease</strong> - If someone shared three words with you, simply type them in. You'll be instantly redirected to the exact page on the web those words are linked to. It’s that simple!
          </li>
          <li>
            <strong>Make your own place easy to remember </strong> - Come up with three unique, meaningful words. If they're not already taken, you can link them to any page on the internet. In future you will no longer need to search this place, because you will remember and instantly find and share it.
          </li>
        </ul>



        <button onClick={onClose} className="popup-close-button">
          Let's do it
        </button>
      </div>
    </div>
  );
}
