import React from 'react';
import './ImpressumPage.css';

function ImpressumPage() {
  return (
    <div className='row impressum-container' style={{ padding: 0, margin: 0 }}>
      <div className='col-md-3'></div>
      <div className='col-md-6'>
        <h2 className="impressum-title">Imprint</h2>

        <div className="impressum-info">
          <p>Mark Schutera<br></br>
            Am Bläsiberg 18<br></br>
            Weingarten<br></br>
            Germany
          </p>

          <p>E-Mail: mark.schutera@mailbox.org<br></br>
            Tel: +4915207137061</p>

          <h3>Why Search when you can Remember</h3>
          <p>Why Three Words Make All the Difference - In a world filled with complex URLs, sharing and remembering a link shouldn’t be a hassle. Every day, we copy, paste, and try to recall long, confusing web addresses that are nearly impossible to remember or communicate. That’s where we come in. Our solution transforms lengthy URLs into three simple words—clear, human-readable combinations that are easy to share, easy to say, and instantly remembered. Imagine being able to direct someone to a page with phrases like “This.Is.Me” or “Explore.Here.Now.” No more lost links, no more confusing codes—just meaningful words that make you remember places on the internet as naturally. Whether you're sharing information at work, connecting with friends, or accessing your favorite resources, our three words bring clarity and simplicity to the internet. We transform search into remember. This is the future of a truly human-centered web.
          </p>

          <h3>Liability for Contents</h3>
          <p>The content of our pages has been created with the utmost care. However, we cannot guarantee the accuracy, completeness, and timeliness of the content. As a service provider, we are responsible for our own content on these pages under general law pursuant to Section 7 (1) of the German Telemedia Act (TMG). According to Sections 8 to 10 TMG, we as a service provider are not obligated to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information under general law remain unaffected by this. However, liability in this regard is only possible from the time of knowledge of a specific legal violation. Upon becoming aware of such legal violations, we will remove this content immediately.</p>

          <h3>Data Protection</h3>
          <p>The use of our website is usually possible without providing personal data. As far as personal data (e.g., name, address, or email address) is collected on our pages, this is always done on a voluntary basis, as far as possible. We do not pass on your data to third parties without your express consent. We also point out that data transmission over the Internet (e.g., communication by email) may have security vulnerabilities. Complete protection of data from access by third parties is not possible. We expressly object to the use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information materials. The operators of these pages expressly reserve the right to take legal action in the event of unsolicited sending of advertising information, such as spam emails.</p>

          <p>Imprint generated with the help of agb.de</p>
        </div>
      </div>
      <div className='col-md-3'></div>
    </div>
  );
}

export default ImpressumPage;
