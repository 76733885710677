import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: '40px',
    backgroundColor: 'rgba(0, 0, 0, 0.0)',

    width: "100%",
    margin: "0",

    border: 'none', // Remove all borders

    borderColor: state.isFocused ? '#4cbfa4' : '#ced4da',
    borderBottom: state.isFocused ? '2px solid #4cbfa4' : '2px solid #ced4da',
    boxShadow: state.isFocused ? null : null,
    borderRadius: '0px',
    '&:hover': {
      borderColor: '#6cdfc4',
    },
    cursor: 'text',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#4cbfa4' : state.isFocused ? '#A3E1D2' : '#232723',
    color: state.isSelected ? '#222422' : state.isFocused ? '#222422' : '#CCC',
    // padding: '10px 20px',
    cursor: 'pointer',
  }),
  input: (provided) => ({
    ...provided,
    color: '#FFF',
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    // borderRadius: '5px',
    // marginTop: '5px',
    backgroundColor: '#232723',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6c757d',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#FFF',
  }),
};

const AutocompleteInput = ({ onSelect, placeholder, defaultValue }) => {
  const [inputValue, setInputValue] = useState(defaultValue || ''); // Initialize with defaultValue
  const [userTypedInputValue, setUserTypedInputValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  const fetchSuggestions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }

    try {
      const response = await fetch(`/api/www/search-words/?q=${encodeURIComponent(inputValue)}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      return data.map((item) => ({ value: item, label: item }));
    } catch (error) {
      console.error('Error fetching words:', error);
      return [];
    }
  };

  const handleSelect = (selectedOption) => {
    setInputValue(selectedOption?.value || '');
    onSelect(selectedOption?.value || '');
  };

  const handleInputChange = (newInput) => {
    setUserTypedInputValue(newInput)
  }

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={fetchSuggestions}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      defaultOptions
      onChange={handleSelect}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      value={inputValue ? { value: inputValue, label: inputValue } : null}
      styles={customStyles}  // custom styles from above
      noOptionsMessage={() => (userTypedInputValue ? 'No options' : 'Start typing...')}
    />
  );
};

export default AutocompleteInput;
