'use client';

import { useState, useEffect } from 'react';
import './toast.css';
import Popup from './popup';

export default function Toast() {
  const [toastOpen, setToastOpen] = useState(false); // Initially hidden
  const [popupOpen, setPopupOpen] = useState(false);
  const [faded, setFaded] = useState(false); // State to control fading
  const showStartTime = 3000; // Delay before showing toast
  const fadeDuration = 3000; // Time before applying fade
  const removeDelay = 500; // Time after fade to remove toast (adjust for CSS transition duration)

  useEffect(() => {
    // Show the toast after the initial delay
    const showTimeout = setTimeout(() => setToastOpen(true), showStartTime);
    // Apply fade-out after the fade duration
    const fadeTimeout = setTimeout(() => setFaded(true), showStartTime + fadeDuration);
    // Completely remove the toast after fade-out
    const removeTimeout = setTimeout(() => setToastOpen(false), showStartTime + fadeDuration + removeDelay);

    // Cleanup timeouts on unmount
    return () => {
      clearTimeout(showTimeout);
      clearTimeout(fadeTimeout);
      clearTimeout(removeTimeout);
    };
  }, []);

  // Handle toast click to show the popup
  const handleToastClick = () => {
    console.log('Toast clicked');
    setPopupOpen(true);
    setToastOpen(false); 
  };

  return (
    <>
      {toastOpen && (
        <div className={`toast-container ${faded ? 'faded' : ''}`} onClick={handleToastClick}>
          <div className="toast-box">
            <span className="toast-text">
              New here? Tap to get started.
            </span>
          </div>
        </div>
      )}

      {/* Render the Popup component if it's open */}
      {popupOpen && <Popup onClose={() => setPopupOpen(false)} />}
    </>
  );
}
